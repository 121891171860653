import axios from "axios";
import { ResponseContainer } from "./BaseApi";
import { authErrorLogout } from "../App";


export const MAIN_URL = process.env.REACT_APP_VITE_API_URL

export enum P {
	// Authorisation & registration - авторизация, регистрация, смена пароля и email
	Login = "/login",
	Logout = "/logout",
	Registration = "/registration",
	Verification = "/verification",
	VerifiConfirm = "/verification/confirm",
	ResetPassword = "/reset-password",
	// User profile - действия над данными пользователя
	Profile = "/profile",
	Logo = "/logo",
	Password = "/password",
	// History
	History = "/history",
	// Basket - непосредственно работа с биржей
	SearchRef = "/search-ref",
	UploadSpec = "/upload-spec",
	UploadSpecJson = "/upload-spec-json/<id>",
	DownloadSpec = "/download-spec",
	DownloadTemplate = "/download-template",
	Scenarios = "/get-scenarios",
	Distribution = "/distribution-info",
	AutoDistribute = "/auto-distribute",
	MakeOrder = "/make-order",
	//Other
	Payload = "/payload",
	//Admin
	//admin profile & auth
	AdminLogin = "/admin/login",
	AdminLogout = "/admin/logout",
	AdminProfile = "/admin/profile",
	//admin администраторы
	Admins = "/admin/admins",
	Admin = "/admin/admin/<id>",
	//admin партнеры
	Partners = "/admin/partners",
	CreatePartner = "/admin/partner",
	Partner = "/admin/partner/<id>",
	PartnerLogo = "/admin/partner/<id>/logo",
	PartnerToken = "/admin/partner/<id>/gen-token",
	//admin аналитика
	Analytics = "/admin/analytics",
	DownloadStocks = '/admin/download-stocks',
	DB = '/admin/db',
	Lines = "/admin/search-line",
	//admin пользователи
	User = "/admin/user/<id>",
	Users = "/admin/users",
}

export const getCurrentUrl = (endpoint: string, elId: string) => {
	const regex = /<id>/g;
	const resultUrl = endpoint.replace(regex, elId);
	return resultUrl;
};
export async function requestFileGet(
	currentUrl: string,
	currentFileName?: string,
	fileType?: string,
	downloadErrorHandler?:(errorMsg: string) => void,
	headers?: object,
) {
	return axios.get(`${MAIN_URL}${currentUrl}`, {
		responseType: 'blob',
		headers: headers,
		withCredentials: true
	}).then((r) => {
		const blob = new Blob([r.data], { type: r.headers['content-type'] || 'application/octet-stream' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		const disposition = r.headers['content-disposition'];
		let filename = (currentFileName && fileType) ? `${currentFileName}.${fileType}` : "newFile.xlsx";
		if (disposition && disposition.indexOf('attachment') !== -1) {
			const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
			const matches = filenameRegex.exec(disposition);
			if (matches != null && matches[1]) {
				filename = matches[1].replace(/['"]/g, '');
			}
		}
		link.download = filename;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	})
	.catch((error) => {
		if (axios.isAxiosError(error)) {
			if (error.response?.status === 401) {
				authErrorLogout && authErrorLogout()
				return
			}
			const contentType = error.response?.headers['content-type'];
			if (contentType && contentType.indexOf('application/json') !== -1) {
				const reader = new FileReader();
				reader.onload = () => {
					try {
						//@ts-ignore
						const errorData = JSON.parse(reader.result);
						downloadErrorHandler && downloadErrorHandler(errorData.message);
					} catch (e) {
						downloadErrorHandler && downloadErrorHandler("Ошибка загрузки файла");
					}
				};
				//@ts-ignore
				reader.readAsText(error.response.data);
			}
			else {
				downloadErrorHandler && downloadErrorHandler("Ошибка загрузки файла");
			}
		}
		downloadErrorHandler && downloadErrorHandler(error.response.data.message)
	})
}

export async function requestFilePost(
	currentUrl: string,
	currentFileName?: string,
	fileType?: string,
	downloadErrorHandler?:(errorMsg: string) => void,
	inData?: unknown,
	headers?: object,
) {
	return axios.post(`${MAIN_URL}${currentUrl}`, inData, {
		responseType: 'blob',
		headers: headers,
		withCredentials: true
	}).then((r) => {
		const blob = new Blob([r.data], { type: r.headers['content-type'] || 'application/octet-stream' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		const disposition = r.headers['content-disposition'];
		let filename = (currentFileName && fileType) ? `${currentFileName}.${fileType}` : "newFile.xlsx";
		if (disposition && disposition.indexOf('attachment') !== -1) {
			const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
			const matches = filenameRegex.exec(disposition);
			if (matches != null && matches[1]) {
				filename = matches[1].replace(/['"]/g, '');
			}
		}
		link.download = filename;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	})
	.catch((error) => {
		if (axios.isAxiosError(error)) {
			if (error.response?.status === 401) {
				authErrorLogout && authErrorLogout()
				return
			}
			const contentType = error.response?.headers['content-type'];
			if (contentType && contentType.indexOf('application/json') !== -1) {
				const reader = new FileReader();
				reader.onload = () => {
					try {
						//@ts-ignore
						const errorData = JSON.parse(reader.result);
						downloadErrorHandler && downloadErrorHandler(errorData.message);
					} catch (e) {
						downloadErrorHandler && downloadErrorHandler("Ошибка загрузки файла");
					}
				};
				//@ts-ignore
				reader.readAsText(error.response.data);
			}
			else {
				downloadErrorHandler && downloadErrorHandler("Ошибка загрузки файла");
			}
		}
		else {
			downloadErrorHandler && downloadErrorHandler("Ошибка загрузки файла");
		}
	})
}

export async function requestGet<R>(
	url: string,
	queryParams?: unknown,
	headers?: object,
	logoutWithAuthError?: boolean
): Promise<ResponseContainer<R>> {
	return axios.get(MAIN_URL + url, {
		withCredentials: true,
		headers: headers,
		params: queryParams,
	}).then((r) => {
		return r as unknown as ResponseContainer<R>;
	})
		.catch((error) => {
			if (axios.isAxiosError(error)) {
				if (error.response?.status === 401 && !logoutWithAuthError) {
					authErrorLogout && authErrorLogout()
				}
			}
			return { status: "error", message: error.response?.data.message };
		});
}

export async function requestPost<R>(
	url: string,
	inData?: unknown,
	headers?: object,
): Promise<ResponseContainer<R>> {
	return axios
		.post(MAIN_URL + url, inData, { withCredentials: true, headers: headers })
		.then((r) => {
			return r as unknown as ResponseContainer<R>;
		})
		.catch((error) => {
			if (axios.isAxiosError(error)) {
				if (error.response?.status === 401) {
					authErrorLogout && authErrorLogout()
				}
			}
			return { status: "error", message: error.response?.data.message };
		});
}

export async function requestPostForm<R>(
	url: string,
	inData: unknown,
): Promise<ResponseContainer<R>> {
	return axios.post(MAIN_URL + url, inData, {
		withCredentials: true,
		headers: { "Content-Type": "multipart/form-data" },
	}).then((r) => {
		return r as unknown as ResponseContainer<R>;
	})
		.catch((error) => {
			if (axios.isAxiosError(error)) {
				if (error.response?.status === 401) {
					authErrorLogout && authErrorLogout()
				}
			}
			return { status: "error", message: error.response?.data.message };
		});;
}

export async function requestPut<R>(
	url: string,
	inData: unknown,
	headers?: object,
): Promise<ResponseContainer<R>> {
	return axios.put(MAIN_URL + url, inData, { withCredentials: true, headers: headers }).then((r) => {
		return r as unknown as ResponseContainer<R>;
	})
		.catch((error) => {
			if (axios.isAxiosError(error)) {
				if (error.response?.status === 401) {
					authErrorLogout && authErrorLogout()
				}
			}
			return { status: "error", message: error.response?.data.message };
		});;
}

export async function requestDelete<R>(
	url: string,
	args?: unknown,
): Promise<ResponseContainer<R>> {
	return axios.delete(MAIN_URL + url, { withCredentials: true, data: args }).then((r) => {
		return r as unknown as ResponseContainer<R>;
	})
		.catch((error) => {
			if (axios.isAxiosError(error)) {
				if (error.response?.status === 401) {
					authErrorLogout && authErrorLogout()
				}
			}
			return { status: "error", message: error.response?.data.message };
		});
}

