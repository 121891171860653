import { FC } from "react";
import { IPartnerAdmin } from "../../../../../../assets/interfaces/IPartner";
import systemeIcon from "../../../../../../assets/systemeIcon.svg"
import clsx from "clsx";

interface IProps {
    data: IPartnerAdmin;
    onClick: () => void;
}

const PartnerAdminListItem: FC<IProps> = ({ data, onClick }) => {
    return (<div onClick={onClick} className={clsx("w-[186px] h-56 bg-gray-1 rounded-lg flex flex-col p-4 items-center gap-8 cursor-pointer hover:bg-gray-2 transition-all relative",
        data.isSuper && "border border-accent")}>
            {data.isSuper && <span className="absolute right-1 top-1 w-10 h-10 rounded-full bg-accent-lite flex items-center justify-center text-accent">
                S
            </span>}
        <img className="w-20 h-20 select-none rounded-md" src={data.avatar || systemeIcon} alt="logo" />
        <p className="w-full text-left text-base">{data.name}</p>
    </div>);
}

export default PartnerAdminListItem;