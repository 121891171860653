import {P, requestDelete, requestGet, requestPost, requestPostForm, requestPut} from "../../requester";
import {
	ChangePasswordRequest,
	ChangeProfileRequest,
	getProfileResponse,
} from "./profileInterfaces";

export function GetProfileInfo(logoutWithAuthError?: boolean) {
	return requestGet<getProfileResponse>(P.Profile, undefined, undefined, logoutWithAuthError);
}

export function ChangeProfileInfo(args: ChangeProfileRequest) {
	return requestPut<null>(P.Profile, args);
}

export function ChangePassword(args: ChangePasswordRequest) {
	return requestPut<null>(P.Password, args);
}
