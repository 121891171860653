import { P, requestGet, requestPost, requestPostForm } from "../../requester";
import { AdminLoginRequest, GetAdminProfileResponse } from "./AdminAuthInterfaces";

export function AdminLogin(args: AdminLoginRequest) {
    return requestPostForm<null>(P.AdminLogin, args)
}

export function AdminLogout() {
    return requestPost<null>(P.AdminLogout)
}

export function GetAdminProfile(logoutWithAuthError?: boolean) {
    return requestGet<GetAdminProfileResponse>(P.AdminProfile, undefined, undefined, logoutWithAuthError)
}