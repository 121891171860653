import { FC, useEffect, useState } from "react";
import ModalBase from "../UI/modals/ModalBase";
import IconButton from "../UI/buttons/IconButton";
import CrossIcon from "../../assets/icons/CrossIcon";
import SearchBox from "../UI/inputs/textInput/SearchBox";
import ScrollArea from "../UI/wrappers/ScrollArea";
import { GetLinesRequest, ILine } from "../../API/admin/analyticsApi/analyticsInterfaces";
import Checkbox from "../UI/inputs/checkBox/CheckBox";
import { GetLines } from "../../API/admin/analyticsApi/analyticsFunctions";
import { toast } from "react-toastify";
import Loader from "../UI/loader/loader";
import { scrollListTop } from "../../utils/reusedFunks";


interface IProps {
    open: boolean;
    onClose: () => void;
    selectedList: string[];
    onChangeSelected: (name: string) => void;
    onClearSelected: () => void;
}


const SelectLineModal: FC<IProps> = ({ open, onClose, selectedList, onChangeSelected, onClearSelected }) => {
    const [searchText, setSearchText] = useState<string>()
    const [lineList, setLineList] = useState<ILine[]>([])
    const [page, setPage] = useState<number>(0)
    const [isReloadList, setIsReloadList] = useState<boolean>(false)
    const [totalLinesCount, setTotalLinesCout] = useState<number>(0)

    useEffect(() => {
        if (page > 0) {
          onGetLines(page, searchText)
        }
      }, [page])

    const onGetLines = (page: number, searchText: string | undefined) => {
        const itemsLimit = 20
        let reqParams: GetLinesRequest = { pagination: { page: page, pageSize: itemsLimit } }
        if (searchText) {
          reqParams = { ...reqParams, searchText: searchText }
        }
        if (page === 0) {
            setIsReloadList(true)
        }
        GetLines(reqParams).then((r) => {
          if (r.status !== "error" && r.data) {
            if (page === 0) {
              scrollListTop("linesList")
              setLineList(r.data.lines)
              setPage(0)
            }
            else {
                setLineList([...lineList, ...r.data.lines])
            }
            setTotalLinesCout(r.data.totalCount)
          }
          setIsReloadList(false)
        })
      }

    const showLinesList = () => {
        return lineList.map((l) => {
            const isSelectedLine = selectedList.findIndex((selectedLine) => selectedLine === l.name) >= 0
            return <div key={l.name} className="w-full h-12  border-b border-gray-2 py-2 flex items-center gap-4">
                <div>
                    <Checkbox checked={isSelectedLine} onCheckedChange={() => onChangeSelected(l.name)} />
                </div>
                <p>{l.name}</p>
            </div>
        })
    }

    const selectedLinesList = selectedList.map((l) => {
        return <div key={l} className="flex bg-accent-lite rounded-lg py-1 px-2 items-center gap-1">
            <p className="pb-[3px]">{l}</p>
            <span className="cursor-pointer" onClick={() => onChangeSelected(l)}><CrossIcon size={17} className="text-gray-3 transition-all hover:text-accent rounded-sm" /></span>
        </div>
    })

    const onSrollEnd = () => {
        if (lineList && totalLinesCount > lineList.length) {
          setPage(page + 1)
        }
      }

    return (<ModalBase open={open} onOpenChange={onClose}> <div className="w-[1100px] h-[700px] flex flex-col">
        <div className="w-full flex justify-end h-2">
            <IconButton onClick={onClose} className="-translate-y-4 translate-x-4" size="small">
                <CrossIcon />
            </IconButton>
        </div>
        <div className="flex flex-1 flex-col gap-4 overflow-hidden">
            <h1 className="font-medium text-2xl pb-3">Выбор линейки</h1>
            <div className="w-full flex-1 flex border-y border-gray-1 overflow-hidden">
                <div className="flex-1 flex flex-col gap-6 border-r border-gray-1 py-4 pr-8">
                    <div className="flex items-center">
                        <h1 className="w-full font-semibold text-lg">
                            Выбранные линейки
                        </h1>
                        {selectedList.length > 0 && <span onClick={onClearSelected} className="cursor-pointer text-sm text-system-red hover:text-system-red-hover font-medium">Очистить</span>}
                    </div>
                    <div className="w-full flex-1 overflow-hidden">
                        <ScrollArea>
                            <div className="flex flex-wrap gap-2">
                                {selectedLinesList.length > 0 ? selectedLinesList : <p className="text-gray-4">Тут пока пусто</p>}
                            </div>
                        </ScrollArea>
                    </div>
                </div>
                <div className="flex-1 flex flex-col gap-6 border-gray-1 pt-4 pb-2 pl-8 overflow-hidden">
                    <h1 className="w-full font-semibold text-lg">
                        Линейки
                    </h1>
                        <SearchBox onClear={() => onGetLines(0, undefined)}
                         onSearchDebounced={(searchText: string) => onGetLines(0, searchText)}
                         onChangeText={setSearchText} placeholder="Поиск" />
                    <div className="flex-1 flex flex-col overflow-hidden">
                        <span className="w-full h-6 bg-gray-1 flex items-center text-gray-4 pl-1 border-b border-gray-2">
                            Список линеек
                        </span>
                       
                            {!isReloadList ? <ScrollArea className="flex-1" id="linesList" onScrollEnd={onSrollEnd}>
                                {showLinesList()}
                            </ScrollArea>: <Loader/>}

                    </div>
                </div>
            </div>
        </div>
    </div>
    </ModalBase>);
}

export default SelectLineModal;