import { IPartnerAdmin } from "../../../assets/interfaces/IPartner";
import { P, getCurrentUrl, requestDelete, requestGet, requestPost, requestPostForm, requestPut } from "../../requester";
import { CreatePartnerRequest, CreatePartnerResponse, EditPartnerRequest, GetPartnersRequest, GetPartnersResponse, IPartnerToken, LoadPartnerLogoResponse } from "./partnersInterfaces";


export function GetPartners(args: GetPartnersRequest) {
    return requestPost<GetPartnersResponse>(P.Partners, args)
}

export function CreatePartner(args: CreatePartnerRequest) {
    return requestPost<CreatePartnerResponse>(P.CreatePartner, args)
}

export function EditPartner(partnerId: string, args: EditPartnerRequest) {
    const url = getCurrentUrl(P.Partner, partnerId)
    return requestPut<null>(url, args)
}

export function DeletePartner(partnerId: string) {
    const url = getCurrentUrl(P.Partner, partnerId)
    return requestDelete<null>(url)
}

export function LoadPartnerLogo(partnerId: string, file: Blob) {
    const url = getCurrentUrl(P.PartnerLogo, partnerId)
    const form = new FormData();
    form.append("file", file);
    return requestPostForm<LoadPartnerLogoResponse>(url, form);
}

export function GetPartnerData(partnerId: string){
    const url = getCurrentUrl(P.Partner, partnerId)
    return requestGet<IPartnerAdmin>(url);
}

export function GetPartnerToken(partnerId: string){
    const url = getCurrentUrl(P.PartnerToken, partnerId);
    return requestPost<IPartnerToken>(url);
}

