import { IReport } from "../../../components/pages/adminPages/parts/AdminAnalytics/interfaces/IAdminAnalytics";
import { P, requestFilePost, requestPost } from "../../requester";
import { GetLinesRequest, GetLinesResponse, IReportRequest } from "./analyticsInterfaces";


export function DownloadReport(args: IReportRequest, fileFormat: "csv" | "xlsx", errorHandler?: (erorMsg: string) => void) {
    return requestFilePost(P.Analytics, "Report", fileFormat, errorHandler, args)
}

export function GetLines(args: GetLinesRequest) {
    return requestPost<GetLinesResponse>(P.Lines, args)
}


