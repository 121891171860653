import { FC, useEffect, useState } from "react";
import ScrollArea from "../../../../UI/wrappers/ScrollArea";
import Button from "../../../../UI/buttons/Button";
import PlusIcon from "../../../../../assets/icons/PlusIcon";
import Select from "../../../../UI/inputs/dropdown/Select";
import { IPartnerAdmin, PartnerCategory } from "../../../../../assets/interfaces/IPartner";
import PartnerAdminListItem from "./components/PartnerAdminListItem";
import CreatePartnerModal from "../../../../modals/CreatePartnerModal";
import EditPartnerWindow from "./components/EditPartnerWindow";
import { CreatePartner, DeletePartner, EditPartner, GetPartners } from "../../../../../API/admin/partnersApi/partnersFunctions";
import { CreatePartnerRequest, EditPartnerRequest, GetPartnersRequest } from "../../../../../API/admin/partnersApi/partnersInterfaces";
import { toast } from "react-toastify";
import LoaderModal from "../../../../UI/loader/LoaderModal";
import { scrollListTop } from "../../../../../utils/reusedFunks";
import { AdminRole } from "../../../../../API/admin/adminAuthApi/AdminAuthInterfaces";

interface IProps {
  userRole: AdminRole
}

const Partners: FC<IProps> = ({userRole}) => {
  const partnersPageSize = 50;
  const partnerFilts = [
    { name: "Все партнеры", value: "all" },
    { name: "По корзине и бирже", value: "basket" },
    { name: "По бирже", value: "exchange" }
  ]
  const [selectedParnersFilt, setSelectedPartnersFilt] = useState<PartnerCategory | "all">("all");
  const [partners, setPartners] = useState<IPartnerAdmin[]>([]);
  const [createPartnerModal, setCreatePartnerModal] = useState<boolean>(false)
  const [editingPartner, setEditingPartner] = useState<IPartnerAdmin>()
  const [getPartnersLoading, setGetPartnersLoading] = useState<boolean>(false)
  const [changeLoading, setChangeLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [totalPartnersCount, setTotalPartnersCount] = useState<number>(0)

  useEffect(() => {
    if (page > 0) {
      onGetPartners()
    }
  }, [page])

  useEffect(() => {
    scrollListTop("adminPartnersList")
    onGetPartners(true)
  }, [selectedParnersFilt])


  const onGetPartners = (firstPage?: boolean) => {
    let reqArgs: GetPartnersRequest = {pagination: {page: firstPage  ? 0 : page, pageSize: partnersPageSize}} 
    if (selectedParnersFilt !== "all") {
      reqArgs = {...reqArgs, filterBy:{partnerFilter: selectedParnersFilt}}
    }
    setGetPartnersLoading(true)
    GetPartners(reqArgs).then((r) => {
      if (r.status !== "error" && r.data?.partners) {
        if (page === 0 || firstPage) {
          setPage(0)
          setPartners(r.data?.partners)
        }
        else {
          setPartners([...partners, ...r.data?.partners])
        }
        setTotalPartnersCount(r.data.totalCount)
      }
      setGetPartnersLoading(false)
    })

  }

  const onListScrollEnd = () => {
    if (partners?.length && (partners?.length < totalPartnersCount)) {
      setPage(page + 1)
    }
  }

  const onCreatePartner = (partnerData: CreatePartnerRequest) => {
    setChangeLoading(true)
    CreatePartner(partnerData).then((r) => {
      if (r.status !== "error") {
        onGetPartners()
        setCreatePartnerModal(false)
        toast.success(`Создан партнер ${partnerData.name}`)
      }
      else {
        toast.error(r.message || "Ошибка создания партнера")
      }
      setChangeLoading(false)
    })
  }

  const onEditPartner = (partnerId: string, newPartnerData: EditPartnerRequest) => {
    setChangeLoading(true)
    EditPartner(partnerId, newPartnerData).then((r) => {
          if (r.status !== "error") {
            onGetPartners()
            setEditingPartner(undefined)
            toast.success("Изменения сохранены")
          }
          else {
            toast.error(r.message || "Ошибка при сохранении")
          }
          setChangeLoading(false)
      })

  }

  const onDeletePartner = (uuid: string) => {
    setChangeLoading(true)
    DeletePartner(uuid).then((r) => {
      if (r.status !== "error") {
        onGetPartners()
        setEditingPartner(undefined)
        toast.success("Партнер удален")
      }
      else {
        toast.error("Ошибка при удалении")
      }
      setChangeLoading(false)
    })
  }
  const partnersList = partners?.map((p) => <PartnerAdminListItem onClick={() => setEditingPartner(p)} key={p.id} data={p} />)

  return (
    <div className="flex flex-col w-full gap-8 h-full pt-4">
      {!editingPartner ? <div className="flex flex-col w-full gap-8 h-full">
        <div className="flex items-center w-full px-10">
          <span className="flex-1">
            <Select
              className="!w-[340px]"
              value={selectedParnersFilt} onValueChange={(filtValue: PartnerCategory) => setSelectedPartnersFilt(filtValue)}
              items={partnerFilts} getItemTitle={(item) => item.name}
              getItemValue={(item) => item.value} />
          </span>
          <Button onClick={() => setCreatePartnerModal(true)} noFish className="w-auto rounded-lg"><PlusIcon size={16} /> Новый партнер</Button>
        </div>
        <div className="flex-1 w-full flex flex-col overflow-hidden items-center">
          {partners.length > 0 ?<ScrollArea id="adminPartnersList" onScrollEnd={onListScrollEnd}>
            {<div className="flex gap-4 w-full flex-wrap px-10">
              {partnersList}
            </div>}
          </ScrollArea>: !getPartnersLoading && <div className="flex flex-col w-fit gap-2 h-full justify-center items-center">
            <p>{"Партнеры отсутствуют"}</p>
           {selectedParnersFilt !== "all"  && <Button onClick={() => setSelectedPartnersFilt("all")} variant="text" noFish>Сбросить фильтры</Button>}
            </div>}
        </div>
        <CreatePartnerModal onCreatePartner={onCreatePartner} isOpen={createPartnerModal} toggleOpen={() => setCreatePartnerModal(false)} />
      </div> : <EditPartnerWindow userRole={userRole} deletePartnerFunk={userRole === "admin" ? onDeletePartner: undefined} backFunk={() => setEditingPartner(undefined)} onEditPartner={onEditPartner} partnerData={editingPartner} />}
        <LoaderModal open = {changeLoading || getPartnersLoading}/>
    </div>
  );
}

export default Partners;